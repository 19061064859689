<template>
  <section class="land-search-land">
    <section class="hero-small">
      <div>
        <h1>
          Je recherche un terrain <br>
          constructible
        </h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h6>
          Lorsqu’on se lance dans un projet de construction, la question primordiale est le lieu de construction. Identifier les communes répondant à votre projet de vie. Trouver un terrain à bâtir dans un budget raisonnable. Sélectionner le bon plan de maison compatible avec le terrain et ses besoins. <br>
          Rassurez-vous, car, grâce à son configurateur, Alysia va vous simplifier la vie.
        </h6>
      </div>

      <card-type-img
        title="+ 2000 terrains à vendre principalement en lotissement">
        <template v-slot:content>
          <p>Nous proposons des terrains issus du catalogue de nos partenaires lotisseurs locaux.</p>

          <p>
            <router-link :to="{ name: 'house-in-allotment' }">Les avantages d’un terrain en lotissement</router-link> <strong>sont nombreux…</strong> <br>
            La phase de recherche est d’autant plus réduite qu’elle se limite au choix de votre commune, à la qualité de l’implantation du lotissement (proximité des commerces, principales infrastructures requises) et puis enfin aux caractéristiques du lot lui-même (superficie, orientation, etc). <br>
            <strong>Autre intérêt du terrain loti</strong>, les critères techniques sont simplifiés : constructibilité, viabilisation, administratif…</p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/sold-land.jpg" alt="Terrain à vendre" />
        </template>
      </card-type-img>

      <div class="card-find-house">
        <figure>
          <img src="@/assets/img/pages/find-house-background.png" alt="Maisons Alysia">
        </figure>
        <div>
          <h2>Configurez votre projet terrain + maison</h2>
          <router-link :to="{ name: 'home', hash: '#hero' }" class="link-button"><app-button theme="primary" hasArrow>Commencer</app-button></router-link>
          <router-link :to="{ name: 'configurator' }">En savoir plus</router-link>
        </div>
      </div>

      <card-type-img
        title="Pas de mauvaises surprises ! Des plans compatibles et adaptés aux terrains.">
        <template v-slot:content>
          <p>
            Explorez notre catalogue de maisons qui ne vous présentera <strong>que des modèles compatibles</strong>.<br>
            Vous pouvez modifier les surfaces de votre maison.<br>
            Et si le terrain ne le permet pas, les plans sont automatiquement ajustés.<br>
            <strong>Vous avez donc rapidement une idée plus précise et réaliste de votre projet.</strong>
          </p>
          <p>
            <router-link :to="$route">En savoir plus</router-link>
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/grow-house.jpg" alt="Maison qui s'agrandit" />
        </template>
      </card-type-img>

      <div class="card-content">
        <h2>Le budget de votre projet de construction en moins de 2 minutes</h2>
        <div class="cards">
          <div class="grid-x">
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/budget-icon.png" alt="Mensualités" />
                <p>Budget tout compris</p>
              </div>
            </div>
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/mensualites-icon.png" alt="Mensualités" />
                <p>Mensualités personnalisées</p>
              </div>
            </div>
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/notice-icon.png" alt="Mensualités" />
                <p>Descriptif à télécharger</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="departments" v-if="departments && departments.length">
        <h2>Où faire construire avec Maisons Alysia ?</h2>
        <p>
          Vous avez déjà trouvé votre terrain, c’est par <router-link :to="{ name: 'land-own-land' }">ici</router-link>
        </p>
        <div class="grid-x grid-margin-x">
          <div class="cell large-4 medium-6 small-12" v-for="department in departments" :key="department.slug">
            <router-link class="card-city" :to="{ name: 'department', params: { dpt: `${department.slug}-${department.content.number}` } }">
              <figure>
                <img :src="department.content.presentationImage.filename" :alt="department.content.presentationImage.alt" />
                <figcaption>
                  <h3>{{ department.content.name }}</h3>
                </figcaption>
              </figure>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';
import Storyblok from '@/services/storyblok';

export default {
  name: 'land-search-land',
  components: {
    CardTypeImg,
  },
  data() {
    return {
      departments: null,
    };
  },
  async mounted() {
    await this.getDepartments();
  },
  methods: {
    async getDepartments() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: 'published',
          starts_with: 'departments/',
        });
        this.departments = response.data.stories.sort((a, b) => a.content.number - b.content.number);
      } catch (err) {
        throw err;
      }
    },
  },
};
</script>

<style lang="sass">
  .land-search-land
    background: $medium-bg
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/land-search-hero.jpg)
    .intro
      margin: 6rem auto 5rem
      padding: 0 1rem
      max-width: 925px
      text-align: center

    .card-type-img
      margin: 3rem auto
      p ~ p
        margin-top: 1.5rem

    .card-find-house
      @include card-find-house
      margin: 3rem auto
      &.left
        h2, h3
          text-align: left

    .card-content
      @include card-content
      display: flex
      align-items: center
      text-align: center
      margin: 1rem 0 5rem
      padding: 5rem 1rem
      background: $primary
      h2
        margin-bottom: 1rem
        max-width: 770px
        color: $white
      p
        max-width: 925px
        &.highlight
          color: $white
          font-size: 18px
          line-height: 29px
      @media (max-width: 768px)
        padding: 3rem 2.5rem 1rem

    .cards
      margin: 3rem 0 2rem
      @media (max-width: 768px)
        margin-top: 1rem

    .card-icon
      @include card-icon
      margin: 0 1rem

  .departments
    display: flex
    flex-direction: column
    align-items: center
    margin: 0 0 6rem 0
    > h2
      text-align: center
    > p
      margin: 2.5rem 0
      a
        color: $primary
        text-decoration: none
    .card-city
      @include card-city
      margin: 18px 0
      @media (max-width: 768px)
        margin: 5px 0
        h3
          width: 100%
          text-align: center
    @media (max-width: 768px)
      padding: 0 14px
</style>
